import 'twin.macro';
import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Paragraph from '../components/atoms/Paragraph';
// import Heading from '../components/atoms/Heading';
import ContactForm from '../components/forms/ContactForm';
import SocialIcons from '../components/SocialIcons';
import config from '../utils/config';

export const query = graphql`
  query EnrolQuery {
    sanitySiteSettings {
      facebook
      twitter
      instagram
      pinterest
      youtube
      telephone
      address
      email
      currentLocation
    }
  }
`;

const EnrolNowPage = ({ data }) => {
  const siteData = data.sanitySiteSettings;
  // eslint-disable-next-line consistent-return
  const handleSubmit = async (formData) => {
    const mailReqData = {
      sender: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
      },
      subject: 'New contact from your yoga website',
      templateId: 1,
      to: [
        {
          email: siteData.email || config.email,
          name: 'Shray',
        },
      ],
      params: formData,
    };

    try {
      await fetch('https://api.sendinblue.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': config.SEND_IN_BLUE_API_KEY,
        },
        body: JSON.stringify(mailReqData),
      }).then(() => {
        alert('Submit Successfully!');
      });
    } catch (err) {
      console.log('err', err);
      alert('Something went wrong, Please try again later.');
      return true;
    }
  };

  return (
    <Layout>
      <Seo title="Enrol Now" />
      <div tw="container mx-auto py-16 relative">
        <div tw="px-4 md:flex justify-between items-center gap-6">
          <div tw="w-full xl:w-4/12 md:w-6/12 py-6">
            {/* <Heading type="h1">Questionnaire for new students</Heading> */}
            <h3 tw="text-4xl font-bold text-primary leading-tight">
              Questionnaire for new students
            </h3>
            <div tw="mt-4">
              <Paragraph size="medium">
                Please fill up the form. Our Team will get back to you within 24
                hours.
              </Paragraph>
              {siteData.email && (
                <div tw="text-gray-500 mt-5">
                  <a href={`mailto:${siteData.email}`}>📧 {siteData.email}</a>
                </div>
              )}
              <div tw="text-gray-500 mt-5">
                <a
                  href={`https://api.whatsapp.com/send?phone=91${
                    data.telephone || config.telephone
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp" tw="text-green-400" /> +91-
                  {siteData.telephone || config.telephone}
                </a>
              </div>
            </div>
            <div tw="mt-6">
              <SocialIcons data={siteData} />
            </div>
          </div>
          <div tw="md:w-6/12 bg-secondary px-8 pt-10 pb-12 flex flex-col w-full rounded-md">
            <ContactForm
              onSubmit={handleSubmit}
              currentLocation={siteData.currentLocation}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default EnrolNowPage;
