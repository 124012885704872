import 'twin.macro';
import React from 'react';

const TextAreaGroup = ({ label, error, ...otherProps }) => (
  <div tw="relative mb-4">
    <label htmlFor={otherProps.name} tw="leading-7 text-sm text-primary">
      {label}
    </label>
    <textarea
      {...otherProps}
      tw="w-full bg-white rounded-md border border-primary focus:border-primary text-sm outline-none text-primary py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"
    />
    {error && <p tw="text-xs italic text-tertiary mt-2">{error}</p>}
  </div>
);
export default TextAreaGroup;
