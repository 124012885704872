/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import 'twin.macro';
import dayjs from 'dayjs';

import InputGroup from '../elements/InputGroup';
import TextAreaGroup from '../elements/TextAreaGroup';
import SelectGroup from '../elements/SelectGroup';

import { Button } from '../atoms';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const RadioGroup = ({ label, value, onChange, options }) => (
  <div tw="sm:flex flex-wrap space-x-3 items-center mb-3">
    <div>
      <label tw="leading-7 text-sm text-primary font-semibold">{label} :</label>
    </div>
    {options &&
      options.map((item) => (
        <div>
          <input
            tw="cursor-pointer"
            type="radio"
            checked={value === item}
            onChange={() => onChange(item)}
          />
          <label htmlFor="isBeginner" tw="leading-7 text-sm text-primary ml-2">
            {item}
          </label>
        </div>
      ))}
  </div>
);
const ContactForm = ({
  values,
  touched,
  errors,
  handleChange,
  isSubmitting,
  handleBlur,
  handleSubmit,
  setFieldValue,
  currentLocation,
}) => (
  <div>
    <form onSubmit={handleSubmit}>
      <div tw="grid md:grid-cols-2 grid-cols-1 md:gap-4">
        <InputGroup
          label="First Name"
          type="text"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.firstName && touched.firstName ? errors.firstName : undefined
          }
        />
        <InputGroup
          label="Last Name"
          type="text"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.lastName && touched.lastName ? errors.lastName : undefined
          }
        />
      </div>

      <div tw="grid md:grid-cols-2 grid-cols-1 md:gap-4">
        <InputGroup
          label="Email"
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email ? errors.email : undefined}
        />
        <InputGroup
          label="Whatsapp Number "
          type="number"
          name="whatsappNumber"
          min="0"
          value={values.whatsappNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.whatsappNumber && touched.whatsappNumber
              ? errors.whatsappNumber
              : undefined
          }
        />
      </div>
      <div tw="grid md:grid-cols-2 grid-cols-1 md:gap-4">
        <InputGroup
          label="Educational Qualification"
          type="text"
          name="educationQualification"
          value={values.educationQualification}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.educationQualification && touched.educationQualification
              ? errors.educationQualification
              : undefined
          }
        />
        <SelectGroup
          label="Joining month of study"
          options={months}
          name="joiningMonth"
          type="text"
          value={values.joiningMonth}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.joiningMonth && touched.joiningMonth
              ? errors.joiningMonth
              : undefined
          }
        />
      </div>
      <InputGroup
        label="Date of Birth"
        type="date"
        name="dateOfBirth"
        value={values.dateOfBirth}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.dateOfBirth && touched.dateOfBirth
            ? errors.dateOfBirth
            : undefined
        }
      />
      <TextAreaGroup
        label="Why do you want to learn Yoga"
        name="reasonOfLearning"
        type="text"
        value={values.reasonOfLearning}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.reasonOfLearning && touched.reasonOfLearning
            ? errors.reasonOfLearning
            : undefined
        }
      />
      <RadioGroup
        label="Mode of Classes"
        value={values.modeOfClasses}
        onChange={(val) => setFieldValue('modeOfClasses', val)}
        options={[
          'Online',
          `Offline ${currentLocation ? `(${currentLocation})` : ''}`,
        ]}
      />
      <RadioGroup
        label="Beginner in Yoga ?"
        value={values.isBeginner}
        onChange={(val) => setFieldValue('isBeginner', val)}
        options={['Yes', 'No']}
      />
      <RadioGroup
        label="Have any Health Issues ?"
        value={values.isHealthIssues}
        onChange={(val) => setFieldValue('isHealthIssues', val)}
        options={['Yes', 'No']}
      />
      <RadioGroup
        label="Have any previous Injuries ?"
        value={values.isPreviousInjuries}
        onChange={(val) => setFieldValue('isPreviousInjuries', val)}
        options={['Yes', 'No']}
      />

      <Button type="submit" isFullWidth disabled={isSubmitting}>
        Submit
      </Button>
    </form>
  </div>
);
export default withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    whatsappNumber: '',
    dateOfBirth: '',
    joiningMonth: '',
    educationQualification: '',
    modeOfClasses: 'Online',
    isBeginner: 'Yes',
    isHealthIssues: 'No',
    isPreviousInjuries: 'No',
    reasonOfLearning: '',
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('First name is required!'),
    dateOfBirth: yup.string().required('Date of birth is required!'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    reasonOfLearning: yup.string().required('Required!'),
    whatsappNumber: yup.string().required('Whatsapp number is required!'),
    educationQualification: yup
      .string()
      .required('Please enter education qualification!'),
    joiningMonth: yup.string().required('Please select joining month!'),
  }),
  handleSubmit: async (values, { setSubmitting, props, resetForm }) => {
    await props.onSubmit({
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format('DD/MM/YYYY'),
    });
    setSubmitting(false);
    resetForm();
  },
  displayName: 'ContactForm',
})(ContactForm);
